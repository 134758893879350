.card {
  width: 100%;
  background-color: #fff;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 2px 0px #1018280f, 0px 1px 3px 0px #1018281a;

  .content {
    display: flex;
    align-items: center;
    column-gap: 16px;
  }

  .title {
    color: #475467;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin: 0;
    padding: 0;
  }

  .number {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    justify-content: start;
    align-items: start;

    p {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      text-align: left;
      color: #101828;
    }
  }
}
