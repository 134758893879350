.container {
  padding: 12px;
  position: relative;
}

.date_picker {
  input {
    background: #fff !important;
  }
}

.dashboard {
  display: flex;
  column-gap: 16px;

  .chart {
    .filters {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      p {
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        text-align: left;
        color: #101828;
      }

      .agent_select {
        width: 100%;
        max-width: 300px;
      }
    }
  }

  .chart_card {
    border: 1px solid #eaecf0;
    border-radius: 12px;
    padding: 16px;
  }
}

.conversion_element {
  padding: 2px 10px;
  background: #ecfdf3;
  border-radius: 16px;

  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: #027a48;
}
