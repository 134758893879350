.ticket {
  width: 100%;
  padding: 16px;
  cursor: pointer;
  gap: 5px;

  background: #f9f9f9;

  border-radius: 12px;
  display: flex;
  align-items: center;
  transition: 0.4s ease-in-out all;
  flex-direction: column;
  border: 2px solid #f9f9f9;

  @media (max-width: 600px) {
    padding: 12px;
  }

  &:hover {
    border: 2px solid var(--primary-color);
  }

  &.onlyRead {
    border: 2px solid rgba(244, 244, 244, 1) !important;
    cursor: initial;
  }

  &.active {
    border: 2px solid var(--primary-color);
    background: none;
    background-color: #fa050928 !important;
  }

  .route {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 46px;
    margin-bottom: 16px;

    .left,
    .right {
      img {
        width: 89px;
        @media (max-width: 600px) {
          height: 16px;
          object-fit: contain;
        }
        max-width: 100px;
        max-height: 50px;
      }
    }

    .left,
    .right {
      display: flex;
      flex-direction: column;
      gap: 7px;

      h3 {
        font-size: 16px;
        font-weight: 700;
        line-height: 19.36px;
        margin: 0;

        @media (max-width: 600px) {
          font-size: 14px;
          line-height: 20px;
        }
      }

      p {
        font-size: 10px;
        font-weight: 400;
        line-height: 12.1px;
        color: rgba(130, 130, 130, 1);
        margin: 0;

        @media (max-width: 600px) {
          font-size: 13px;
        }
      }
    }

    .right {
      justify-content: flex-end;
    }

    .middle {
      display: flex;
      flex-direction: column;

      .date {
        padding-bottom: 5px;
        margin-bottom: 5px;
        border-bottom: 1px solid rgba(232, 232, 232, 1);
        display: flex;
        column-gap: 60px;
        align-items: center;
        color: rgba(90, 90, 90, 1);
        min-width: 240px;
        max-width: 280px;
        justify-content: space-between;
      }

      .iatas {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          color: rgba(12, 12, 12, 1);
          font-size: 12px;
          font-weight: 600;
          line-height: 20px;
          margin: 0;
        }
      }
    }
  }

  .price_form {
    display: none;
    align-items: center;
    column-gap: 20px;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid #e8e8e8;
    padding-top: 16px;

    &:last-child {
      border: none;
    }

    .row {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      width: 100%;

      p {
        margin-bottom: 6px;
      }
    }

    .input {
      background: #fff;
      height: 28px;
      border: 0.5px solid #828282;
      border-radius: 4px;

      input {
        font-size: 12px;
        line-height: 20px;
        padding: 4px 8px;
      }
    }
  }

  .price_form_active {
    display: flex;
  }

  .client_mode {
    width: 100%;
    border-top: 1px solid #e8e8e8;
    padding-top: 16px;
    display: none;

    p {
      font-size: 12px;
      font-weight: 700;
      line-height: 20px;
      text-align: left;
      color: #667085;

      span {
        color: #000;
        font-weight: 500;
      }
    }
  }

  .client_mode_active {
    display: block;
  }
}

.ticketCard {
  display: flex;
  align-items: start;
  column-gap: 42px;
  background: #fff;
  transition: all 0.3s ease;
  box-sizing: border-box;
  padding: 80px;
  min-width: 1340px;
}

.ticketBlock {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;

  &.active {
    border-color: #ddd;
    background-color: #f9fafb;
  }

  &.onlyRead {
    cursor: default;
  }
}

.itineraryBlock {
  .itineraryTopText {
    h2 {
      font-weight: 500;
      font-size: 48px;
      line-height: 60px;
      letter-spacing: -2%;
      color: #1c1c1c;
      margin-bottom: 12px;
    }

    p {
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0%;
      color: #585757;
    }
  }
}

.itineraryHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;

  .airlineInfo {
    display: flex;
    align-items: center;
    gap: 1rem;

    .logoCircle {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      border: 1px solid #e4e7ec;

      img {
        width: 70%;
        height: auto;
        object-fit: contain;
      }
    }

    .airlineName {
      font-size: 1.125rem;
      font-weight: 600;
      color: #101828;
    }
  }

  .dashedLine {
    flex: 1;
    border-bottom: 1px dashed #d0d5dd;
    margin: 0 0.75rem;
  }

  .flightTime {
    font-size: 0.875rem;
    color: #667085;
    font-weight: 500;
    white-space: nowrap;
  }
}

.segmentsContainer {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.flightSegment {
  background: #f9fafb;
  border-radius: 20px;
  padding: 32px;
  display: flex;
  border: 1px solid #e4e7ec;
  flex-direction: column;
  gap: 32px;
  box-shadow: 0px 1px 2px 0px #1018280f, 0px 1px 3px 0px #1018281a;

  .segmentHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 32px;

    .logoCircle {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      background: #fff;
      border: 1px solid #e4e7ec;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      img {
        width: 70%;
        height: auto;
        object-fit: contain;
      }
    }

    .airlineName {
      font-weight: 500;
      font-size: 36px;
      line-height: 44px;
      letter-spacing: -2%;
      color: #1c1c1c;
      flex: 1;
    }

    .flightTime {
      font-family: Inter;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0%;
      color: #585757;
      white-space: nowrap;
    }
  }

  .dashedLine {
    width: 100%;
    border-bottom: 4px dashed #e8e8e8;
    margin: 0.25rem 0;
  }

  .segmentRow {
    display: flex;
    align-items: center;
    gap: 1rem;

    .colLeft {
      display: flex;
      align-items: center;
      gap: 32px;
      margin-right: 64px;

      .iconContainer {
        width: 70px;
        height: 70px;
        background-color: #f2f2f2;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 32px;
          height: 32px;
          color: #667085;

          path {
            fill: #667085;
          }
        }
      }

      .timeContainer {
        display: flex;
        flex-direction: column;

        .time {
          font-weight: 500;
          font-size: 24px;
          line-height: 32px;
          letter-spacing: 0%;
          color: #1c1c1c;
          margin-bottom: 6px;
        }
        .date {
          font-family: Inter;
          font-weight: 400;
          font-size: 20px;
          line-height: 30px;
          letter-spacing: 0%;
          color: #969696;
        }
      }
    }

    .colRight {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .city {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0%;
        color: #1c1c1c;
        margin-bottom: 6px;
      }
      .airport {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0%;
        color: #969696;
      }
    }
  }
}

.layoverBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f9fafb;
  border: 1px solid #e4e7ec;
  border-radius: 20px;
  padding: 32px;
  margin: 40px 0;
  box-shadow: 0px 1px 2px 0px #1018280f, 0px 1px 3px 0px #1018281a;

  .layoverLeft {
    display: flex;
    align-items: center;
    gap: 19px;

    svg {
      font-size: 44px;
      color: #667085;
    }

    span {
      font-weight: 600;
      font-size: 30px;
      line-height: 38px;
      letter-spacing: 0%;
      color: #969696;
    }
  }

  .layoverRight {
    font-weight: 500;
    font-size: 30px;
    line-height: 38px;
    letter-spacing: 0%;
    color: #1c1c1c;
  }
}

.roundtripDivider {
  width: 100%;
  height: 4px;
  background-color: #e8e8e8;
  margin: 40px 0;
}

.priceBlock {
  background-color: #f9fafb;
  border-radius: 20px;
  padding: 12px 0;
  margin-top: 144px;
  width: 100%;
  border: 1px solid #e4e7ec;
  max-width: 420px;
  box-shadow: 0px 1px 2px 0px #1018280f, 0px 1px 3px 0px #1018281a;

  p {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 20px 57px 20px 32px;

    span {
      font-family: Inter;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0%;
      color: #667085;
    }
  }
}

.ticketHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 40px 60px;
  border-bottom: 1px solid #ececec;
  box-sizing: border-box;

  .logoBlock {
    img {
      object-fit: cover;
    }
  }

  .userBlock {
    display: flex;
    align-items: center;
    gap: 16px;

    .userInfo {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      h3 {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0%;
        color: #101828;
        margin-bottom: 6px;
      }

      span {
        font-family: Inter;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0%;

        color: #475467;
      }
    }

    .avatarWrapper {
      position: relative;

      img {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        object-fit: cover;
      }

      .onlineDot {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 20px;
        height: 20px;
        border: 2px solid #fff;
        border-radius: 50%;
        background-color: #22c55e;
      }
    }
  }
}
