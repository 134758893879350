.box {
    width: 660px;
    display: flex;
    align-items: start;
    flex-direction: column;
    // padding: 24px;

    @media (max-width: 600px) {
        width: 100%;
        padding: 12px;
    }

    .close {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
        opacity: 0.5;
    }

    .header {
        display: flex;
        align-items: center;
        column-gap: 16px;
        padding: 24px;
        border-bottom: 1px solid #EAECF0;
        width: 100%;

        .add_user {
            background: #fff;
            border: 1px solid #EAECF0;
            box-shadow: 0px 1px 2px 0px #1018280D;
            padding: 12px;
            border-radius: 10px;
            width: 100%;
            max-width: 48px;
            height: 100%;
            max-height: 48px;
        }

        h3 {
            font-size: 18px;
            font-weight: 600;
            line-height: 28px;
            text-align: left;
            color: #101828;
        }

        p {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: #475467;
        }
    }

    .body {
        display: flex;
        align-items: center;
        flex-direction: column;
        row-gap: 24px;
        padding: 24px;
        width: 100%;
        max-height: 500px;
        overflow: auto;

        .previews {
            display: grid;
            grid-template-columns: 2fr 2fr;
            gap: 10px;
            margin-top: 10px;
            width: 100%;
        }


        input {
            background: #fff;
        }

    }

    .footer {
        display: flex;
        align-items: center;
        padding: 24px;
        width: 100%;
        border-top: 1px solid #EAECF0;
        justify-content: flex-end;


        .btn_group {
            display: flex;
            align-items: center;
            column-gap: 12px;
        }

        .save_button {
            color: #fff;
            border: 1px solid #FEF3F2;
            background: #D92D20;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            border-radius: 8px;
            width: 100%;
        }

        .cancel_button {
            color: #344054;
            background: #fff;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            border-radius: 8px;
            border: 1px solid #D0D5DD;
            box-shadow: 0px 1px 2px 0px #1018280D;
            width: 100%;
        }

    }



}

.dragOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #333;
    z-index: 100;
    border: 2px dashed #ccc;
}