.container {
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .select {
  }

  .params {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .box {
      padding: 0 5px;
      background-color: #fff;
      border-radius: 8px;
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 20%;
      column-gap: 16px;
    }

    .btn_group {
      display: flex;
      gap: 10px;
    }
  }
}

.one_way {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;

  .MuiFormControl-root {
    background-color: #fff !important;
  }

  .wrapper {
    width: 300px;
    height: 56px;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    align-items: center;
  }
}

.difficult_route {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .routes {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;

    .wrapper {
      width: 280px;
      height: 56px;
      background-color: #fff;
      border-radius: 8px;
      display: flex;
      align-items: center;

      .MuiFormControl-root {
        background-color: #fff !important;
      }
    }

    .remove_btn {
      outline: none;
      border: none;
      background-color: #fff;
      width: 56px;
      height: 56px;

      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      color: #c1c1c1;
      box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.03);

      svg {
        color: rgba(147, 147, 147, 1);
      }
    }
  }
}

.round_trip {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;

  .MuiFormControl-root {
    background-color: #fff !important;
  }

  .wrapper {
    width: 25%;
    height: 56px;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    align-items: center;
  }
}

.tickets {
  z-index: 9;
  width: 100%;
  display: flex;
  background-color: #fff;
  border-radius: 12px;
  padding: 12px;
  overflow-y: scroll;
  height: 100%;
  max-height: 350px;

  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    h2 {
      margin: 0;
    }

    .list {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 5px;
    }
  }
}
