.image {
  width: 100%;
  height: 100%;
  max-width: 300px;
  max-height: 200px;
  border-radius: 12px;

  img {
    object-fit: cover;
  }

  &.merged {
    padding: 8px;
    border-radius: 10px 10px 0 0;
    background: #fef5f5;
  }

  &.other {
    background: #fff !important;
    border: 1px solid #eaecf0 !important;
    border-bottom: none !important;
  }
}

.url_image {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  border-radius: 12px;

  img {
    object-fit: cover;
  }

  &.merged {
    border-radius: 10px 10px 0 0;
    background: #fef5f5;
    margin-bottom: 12px;
  }

  &.other {
    background: #fff !important;
    border: none !important;
  }
}

.resize {
  border: 1px solid #000;
  border-radius: 12px;
  background: #fff;
  padding: 24px;
}

.pip {
  position: fixed;
  left: 0;
  top: 0;
  width: 550px;
  height: auto;

  z-index: 5;

  .close {
    width: fit-content;
    height: 100%;
    max-height: 20px;
    margin-left: auto;
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;

    svg {
      margin-left: auto;
    }
  }
}
